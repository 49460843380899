import hidratacion1 from '../../assets/images/hidratacion/hidratacion1.webp'
import hidratacion2 from '../../assets/images/hidratacion/hidratacion2.webp'
import hidratacion3 from '../../assets/images/hidratacion/hidratacion3.webp'
import hidratacion4 from '../../assets/images/hidratacion/hidratacion4.webp'

const dummyDataBennefits = [
  {
    id: 1,
    backgroundImage: hidratacion1,
    text: 'Energía y Rendimiento: Aumenta la energía y mejora el rendimiento físico al mantener el cuerpo hidratado.',
  },
  {
    id: 2,
    backgroundImage: hidratacion2,
    text: 'Piel Saludable: Hidrata la piel, reduciendo la aparición de arrugas y manteniendo la piel saludable.',
  },
  {
    id: 3,
    backgroundImage: hidratacion3,
    text: 'Salud Digestiva: Mantiene el sistema digestivo hidratado, facilitando la digestión y preveniendo problemas como el estreñimiento.',
  },
  {
    id: 4,
    backgroundImage: hidratacion4,
    text: 'Crecimiento Saludable: El agua filtrada es esencial para el crecimiento y desarrollo saludables de los niños, manteniéndolos hidratados y apoyando su salud general.',
  },
  ]

export default dummyDataBennefits