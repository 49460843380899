import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
import colors from "../../assets/theme/colors"
import AOS from "aos"
import "aos/dist/aos.css"
import Carousel from "react-elastic-carousel"
import CardImg from "../CardImg"
import dummyDataBennefits from "./dummyDataBennefits"


function Benefits() {
  const theme = useTheme()
  const [showArrows, setShowArrows] = useState(true)

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const styles = {
    mainContainer: {
      background: "#e6f6fa", // Degradado desde #e6f6fa hasta transparente
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      paddingY: 6,
      paddingX: 10,
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 6,
        paddingX: 1,
      },
    },
    textContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
        flexDirection: "column",
      },
    },
    title1: {
      // fontFamily: "'Bodoni Moda', serif",
      fontSize: "44px",
      color: colors.black.light,
      fontWeight: 500,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        // paddingTop: 8,
      },
    },
    subTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "15px",
      color: colors.secondary.main,
      fontWeight: 500,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        paddingTop: 3,
      },
    },
    imgContainer: {
      position: "relative",
      width: "50%",
      alignItems: "center",
      justifyContent: "center",

      display: "flex",
      // justifyContent: "space-evenly",
      // zIndex: "-99"
      [theme.breakpoints.down("md")]: {
        width: "100%",
        mt: 8,
      },
    },
    img: {
      // position: "absolute",
      right: 0,
      width: "70%",
      // height: "350px",
      boxShadow: `-1px 1px 12px -3px rgba(0, 0, 0, 0.40)`,
      [theme.breakpoints.down("md")]: {
        height: "250px",
        top: "-13%",

      },
    },
  }


  const breakPoints = [
    { width: 500, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
    { width: 1500, itemsToShow: 1 },
  ]

  useEffect(() => {
    const handleResize = () => {
      // Adjust showArrows based on window width
      setShowArrows(window.innerWidth > 768)
    }

    // Initial setup
    handleResize()

    window.addEventListener("resize", handleResize)

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.textContainer}>

        <Box
          sx={{
            flex: 1,
            ml: { xs: 0, md: 5 },
            paddingX: { xs: 0, md: 10 },
            display: "flex", // Asegura que el Box se comporte como un contenedor flex
            flexDirection: "column", // Alinea los elementos hijos en una columna
            justifyContent: "center", // Centra los elementos hijos horizontalmente
            // alignItems: 'center', // Alinea los elementos hijos en el centro verticalmente
          }}
          flex={1}
          // data-aos={"fade-right"} data-aos-delay={300}
        >
          <Typography sx={{ ...styles.subTitle, fontWeight: 700, color: colors.gold.main, marginBottom: 1 }}>
            Agua pura
          </Typography>
          <Typography variant="h1" sx={styles.title1}>
            Beneficios de la correcta hidratación
          </Typography>
          <Typography sx={{ ...styles.subTitle, marginTop: 2 }}>
            Tu salud y la de tu familia esta primero.
          </Typography>
        </Box>
        <Box sx={styles.imgContainer}>
          <Carousel
            breakPoints={breakPoints}
            showArrows={showArrows}
            enableAutoPlay={true}
            autoPlaySpeed={20000}
            isRTL={false}
          >
            {dummyDataBennefits.map((item, index) => (
              <CardImg key={index} data={item} />
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  )
}

export default observer(Benefits)
