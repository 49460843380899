import React from "react"
import { useTheme } from "@mui/material/styles"
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material"
import corporativo from "../../assets/images/segmentos/corporativo.webp"
import residencial from "../../assets/images/segmentos/residencial.webp"
import evento from "../../assets/images/segmentos/evento1.webp"

function Segmentos() {
  const theme = useTheme()

  const styles = {
    imgContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
      paddingY: 6,
      paddingX: 10,
      gap: 10,
      position: "relative",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    img: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "300px",
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 6,
        paddingX: 1,
      },
    },
    title: {
      position: "absolute", // Posicionamiento absoluto
      top: "85%", // Centrado verticalmente
      left: "50%", // Centrado horizontalmente
      transform: "translate(-50%, -50%)", // Ajuste para centrar perfectamente
      backgroundColor: "white", // Fondo blanco
      padding: "10px", // Espaciado alrededor del texto
      borderRadius: "5px", // Bordes redondeados
      zIndex: 1, // Asegura que el título esté por encima de la imagen
    },
  }

  const dummyData = {
    corporativo: {
      id: 1,
      title: "Corporativo",
      img: corporativo,
      description: "Contribuimos a un entorno de trabajo más saludable y productivo, garantizando un suministro constante de agua purificada para todas las instalaciones corporativas.",
    },
    residencial: {
      id: 2,
      title: "Residencial",
      img: residencial,
      description: "Ofrecemos soluciones de purificación de agua para hogares, garantizando la calidad del agua que consumen las familias.",
    },
    eventos: {
      id: 3,
      title: "Eventos",
      img: evento,
      description: "Proporcionamos servicios de agua purificada para eventos especiales, asegurando que los asistentes tengan acceso a agua limpia y segura en todo momento.",
    }
  }

  return (
    <Box mt={5}>
      <Typography textAlign={"center"} variant="h2">
        Segmentos
      </Typography>
      <Box sx={styles.imgContainer}>
        {Object.values(dummyData).map((item) => (
          <Card sx={{ width: 345 }} key={item.id}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="240"
                image={item.img}
                alt={"Filtros"}
                sx={{ objectFit: "cover" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>

    </Box>
  )
}

export default Segmentos
