import logo3 from '../../assets/images/logos/logo3.webp'
import logo4 from '../../assets/images/logos/logo4.webp'
import logo5 from '../../assets/images/logos/logo5.webp'
import logo6 from '../../assets/images/logos/logo6.webp'
import logo7 from '../../assets/images/logos/logo7.webp'
import logo8 from '../../assets/images/logos/logo8.webp'
import logo9 from '../../assets/images/logos/logo9.webp'
import logo10 from '../../assets/images/logos/logo10.webp'
import logo11 from '../../assets/images/logos/logo11.webp'
import logo12 from '../../assets/images/logos/logo12.webp'
import logo13 from '../../assets/images/logos/logo13.webp'
import logo14 from '../../assets/images/logos/logo14.webp'



const dummyDataClientes = [
  {
    id: 1,
    logo: logo3,
    alt: 'Agua Pura america',
  },
  {
    id: 2,
    logo: logo4,
    alt: 'Agua Pura america',
  },
  {
    id: 3,
    logo: logo5,
    alt: 'Agua Pura america',
  },
  {
    id: 4,
    logo: logo6,
    alt: 'Agua Pura america',
  },
  {
    id: 5,
    logo: logo7,
    alt: 'Agua Pura america',
  },
  {
    id: 6,
    logo: logo8,
    alt: 'Agua Pura america',
  },
  {
    id: 7,
    logo: logo9,
    alt: 'Agua Pura america',
  },
  {
    id: 8,
    logo: logo10,
    alt: 'Agua Pura america',
  },
  {
    id: 9,
    logo: logo11,
    alt: 'Agua Pura america',
  },
  {
    id: 10,
    logo: logo12,
    alt: 'Agua Pura america',
  },
  {
    id: 11,
    logo: logo13,
    alt: 'Agua Pura america',
  },
  {
    id: 12,
    logo: logo14,
    alt: 'Agua Pura america',
  }

]

export default dummyDataClientes