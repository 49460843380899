import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
import fondoHero1 from "../../assets/images/segmentos/hero4.webp"
import colors from "../../assets/theme/colors"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import AOS from "aos"
import "aos/dist/aos.css"

function Hero3() {
  const theme = useTheme()

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const styles = {
    heroContainer: {
      backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6), transparent), url(${fondoHero1})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "720px",
      [theme.breakpoints.down("md")]: {
        height: "570px",
      },
    },
    heroContainer2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      height: "100%",

    },
    textContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    title1: {
      // fontFamily: "'Bodoni Moda', serif",
      fontSize: "69px",
      color: colors.background.default,
      fontWeight: 500,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        // paddingTop: 14,
      },
    },
    subTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "20px",
      color: colors.white.main,
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "2px",
      },
    },
    button: {
      bgcolor: colors.white.main,
      borderRadius: 5,
      mt: 4,
      color: colors.textSideNav.focus,
      "&:hover": {
        bgcolor: "#dee2e6",
      },
    },
    button2: {
      // bgcolor: colors.white.main,
      borderColor: colors.white.main,
      borderRadius: 5,
      mt: 4,
      color: colors.white.main,
      "&:hover": {
        bgcolor: colors.white.main,
        color: colors.textSideNav.focus,

      },
    },
    eslogan: {
      textAlign: "left",
      color: colors.white.main,
      fontStyle: "italic",
      // textDecoration: "underline"
    },
    locationContainer: {
      marginTop: -10,
      marginRight: 10,
      display: "flex",
      flexDirection: "row-reverse",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        marginRight: 1,
        marginTop: -7,
      },
    },
    locationText: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#1c1c1c",
      opacity: "0.65",
      color: "#fff",
      padding: 0.6,
      borderRadius: 2,
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        marginRight: 1,
      },
    },

  }

  return (
    <>
      <Box sx={styles.heroContainer}>
        <Box sx={styles.heroContainer2}>
          <Box sx={styles.textContainer}>
            <Box sx={{ flex: 1, ml: { xs: 0, md: 5 }, paddingX: { xs: 0, md: 10 } }}>
              <Typography variant="h1" sx={styles.title1} data-aos={"fade-up"} data-aos-delay={200}>
                Nos acomodamos a tus necesidades
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.locationContainer}
             onClick={() => window.open("https://maps.app.goo.gl/Y12wQCZ3d3dzPwoN7", "_blank")}>
          <Typography sx={styles.locationText} >
            <LocationOnOutlinedIcon sx={{ marginRight: 1 }} />
            Caacupé, Paraguay
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default observer(Hero3)
