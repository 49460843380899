import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
import colors from "../../assets/theme/colors"
import eventImg8 from "../../assets/images/inicio/filtro1.webp"
import AOS from "aos"
import "aos/dist/aos.css"

function AboutUs() {
  const theme = useTheme()

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const styles = {
    mainContainer: {
      background: "linear-gradient(to bottom, #e6f6fa, transparent)", // Degradado desde #e6f6fa hasta transparente
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      paddingY: 6,
      paddingX: 10,
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 6,
        paddingX: 1,
      },
    },
    textContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
        flexDirection: "column",
      },
    },
    title1: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "44px",
      color: colors.black.light,
      fontWeight: 500,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        // paddingTop: 8,
      },
    },
    subTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "15px",
      color: colors.secondary.main,
      fontWeight: 500,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        paddingTop: 3,
      },
    },
    imgContainer: {
      position: "relative",
      width: "50%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        mt: 8,
      },
    },
    img: {
      right: 0,
      width: "70%",
      boxShadow: `-1px 1px 12px -3px rgba(0, 0, 0, 0.40)`,
      [theme.breakpoints.down("md")]: {
        height: "250px",
        top: "-13%",
      },
    },
  };



  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.textContainer}>

        <Box
          sx={{ flex: 1, ml: { xs: 0, md: 5 }, paddingX: { xs: 0, md: 10 } }}
          flex={1}
          // data-aos={"fade-right"} data-aos-delay={300}
        >
          <Typography sx={{ ...styles.subTitle, fontWeight: 700, color: colors.gold.main, marginBottom: 1 }}>
            Sobre Nosotros
          </Typography>
          <Typography variant="h1" sx={styles.title1}>
            Agua filtrada en tu Hogar
          </Typography>
          <Typography sx={{ ...styles.subTitle, marginTop: 2 }}>
            Desde su fundación en 1990, Agua Pura America ha consolidado su posición como líder en el sector de sistemas
            de filtrado y purificación de agua pretratada. Con un enfoque en la adaptabilidad y la satisfacción del
            cliente, nuestra gama de productos se ha expandido para abarcar diversas necesidades y estilos de vida. Ya
            sea que busques purificar agua para el hogar, mejorar la calidad del agua en tu lugar de trabajo, o
            necesites soluciones para la industria, Agua Pura America tiene la solución perfecta para ti.

            Nuestro compromiso con la calidad y la innovación nos ha permitido desarrollar equipos purificadores de agua
            que no solo mejoran la calidad del agua, sino que también se adaptan a las dinámicas cambiantes de consumo.
          </Typography>
        </Box>
        <Box sx={styles.imgContainer}>
          <Box
            component="img"
            src={eventImg8}
            alt={"Hotel acuario"}
            width={300}
            height={400}
            borderRadius={5}
            sx={styles.img}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(AboutUs)
