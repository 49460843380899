import React from "react";
import { Box, Typography } from "@mui/material";

const expandedData = {
  corporativo: {
    id: 1,
    title: "Corporativo",
    description: "En nuestra línea corporativa, nos enfocamos en proporcionar soluciones integrales de purificación de agua para empresas. Desde pequeñas oficinas hasta grandes corporativos, nuestro objetivo es crear entornos de trabajo más saludables y productivos. Nuestros sistemas de filtración garantizan un suministro constante de agua purificada, reduciendo así los riesgos para la salud asociados con el consumo de agua contaminada. Además, ofrecemos opciones personalizadas para adaptarnos a las necesidades específicas de cada empresa, asegurando la máxima eficiencia y satisfacción del cliente."
  },
  residencial: {
    id: 2,
    title: "Residencial",
    description: "En el ámbito residencial, nos comprometemos a brindar a las familias soluciones confiables de purificación de agua para el hogar. Sabemos lo importante que es para ti y tu familia tener acceso a agua limpia y segura en todo momento. Es por eso que nuestros sistemas de filtración residencial están diseñados para eliminar contaminantes comunes del agua del grifo, como cloro, sedimentos y metales pesados, garantizando así la salud y el bienestar de tu familia. Con nuestras soluciones de filtración residencial, puedes disfrutar de un agua de excelente calidad directamente desde el grifo de tu casa."
  },
  eventos: {
    id: 3,
    title: "Eventos",
    description: "Para tus eventos especiales, ofrecemos servicios de suministro de agua purificada para garantizar que tus invitados tengan acceso a agua limpia y segura en todo momento. Ya sea un evento corporativo, una boda o una celebración comunitaria, nos encargamos de proporcionar soluciones de filtración de agua sin complicaciones. Nuestros sistemas portátiles de purificación de agua garantizan una distribución eficiente y conveniente en cualquier lugar donde se celebre el evento. Así puedes brindar a tus invitados la tranquilidad de saber que están consumiendo agua de la más alta calidad durante toda la celebración."
  }
};

const Descripcion = () => {
  return (
    <Box p={8}>
      {Object.values(expandedData).map((segmento) => (
        <Box key={segmento.id} mb={5}>
          <Typography gutterBottom variant="h5">{segmento.title}</Typography>
          <Typography color="text.secondary">{segmento.description}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Descripcion;
