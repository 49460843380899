import React from "react"
import PageLayout from "../../../components/PageLayout"
import Navbar from "../../../components/NavBar"
import Footer from "../../../components/Footer"
import Hero2 from "../../../components/Hero2"
import Description from "../../../components/AboutUs/description";



const AboutUsPage = () => {


    return (
        <PageLayout background={"white"}>
            <Navbar />
            <Hero2 />
            {/*<AboutUs />*/}
            <Description/>
            <Footer />
        </PageLayout>
    )
}

export default AboutUsPage