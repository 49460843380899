import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import aboutUsImage from "../../assets/images/nosotros/mision.webp";
import colors from "../../assets/theme/colors";
import aboutUsImage2 from "../../assets/images/nosotros/vision.webp";

function AboutUs() {
    const theme = useTheme();

    const horariosData = {
        horarioTitle: "Horarios de Atención",
        days: [
            { id: 1, day: "Lunes - Viernes", hours: "8:00am - 6:00pm" },
            { id: 2, day: "Sábado", hours: "9:00am - 2:00pm" },
            { id: 3, day: "Domingo", hours: "Cerrado" }
        ]
    };

    const valoresData={
        title: "Valores"
    }
    const expandedData = {

        calidad: {
            id: 1,
            title: "Calidad",
            description: "La calidad es más que un compromiso; es nuestra obsesión. Nos esforzamos por ofrecer productos y servicios que no solo cumplan, sino que superen las expectativas de nuestros clientes en términos de excelencia y confiabilidad. Desde la selección cuidadosa de materiales hasta los rigurosos controles de calidad en cada etapa de producción, cada producto que sale de nuestras instalaciones está diseñado para garantizar la máxima satisfacción del cliente. Nuestro compromiso con la calidad se extiende más allá de nuestros productos; también nos esforzamos por brindar un servicio al cliente excepcional."

        },
        innovacion: {
            id: 2,
            title: "Innovación",
            description: "Creemos en la importancia de la innovación continua para satisfacer y superar las expectativas de nuestros clientes. Nos esforzamos por estar a la vanguardia de la tecnología y las prácticas del sector, desarrollando soluciones que mejoren la eficiencia y la efectividad de nuestros productos y servicios. Fomentamos una cultura de creatividad y pensamiento progresista, alentando a nuestro equipo a explorar nuevas ideas y métodos que puedan mejorar nuestras ofertas. Nuestra capacidad para innovar nos permite adaptarnos a las cambiantes necesidades del mercado y brindar soluciones que realmente marcan la diferencia en la vida de las personas."
        },
        sostenibilidad: {
            id: 3,
            title: "Sostenibilidad",
            description: "La protección del medio ambiente es una prioridad para nosotros. Nos dedicamos a implementar prácticas sostenibles en todas nuestras operaciones, promoviendo el uso responsable de los recursos y contribuyendo a un futuro más limpio y saludable para todos. Nos esforzamos por reducir nuestra huella ambiental mediante el uso de materiales ecológicos, procesos eficientes y la promoción del reciclaje. Además, trabajamos para concienciar a nuestros clientes y la comunidad sobre la importancia de la conservación del agua y el medio ambiente, apoyando iniciativas que buscan un impacto positivo duradero en nuestro planeta."
        }
    };

    const aboutUsData = {
        title: "MISIÓN",
        content: `En Agua Pura, nos dedicamos a mejorar la calidad de vida de nuestros clientes proporcionando soluciones integrales de agua pura y saludable. Nuestra misión es ofrecer productos de alta calidad, como filtros de agua, bebederos y servicios de instalación de sistemas de agua, que garanticen la disponibilidad de agua potable segura y fresca en hogares, oficinas y comunidades. Comprometidos con la excelencia, la innovación y la sostenibilidad, buscamos ser líderes en el mercado de la purificación de agua, asegurando que cada gota que entregamos contribuya al bienestar y la salud de las personas. Trabajamos con pasión y dedicación para construir un futuro más saludable y sustentable para todos.`,
    };

    const visionData = {
        title: "VISIÓN",
        content: `Nuestra visión en Agua Pura es ser reconocidos como líderes en la provisión de soluciones de agua pura, estableciendo un estándar de excelencia y confianza en el mercado. Aspiramos a expandir nuestro alcance y nuestra oferta de productos y servicios, innovando continuamente para satisfacer las crecientes necesidades de nuestros clientes. Nos esforzamos por crear un impacto positivo en la sociedad y en el medio ambiente, promoviendo prácticas sostenibles y responsables en todas nuestras operaciones. Imaginamos un futuro donde el acceso a agua potable de alta calidad sea universal, contribuyendo a la salud y el bienestar de las comunidades a las que servimos. Con un compromiso inquebrantable con la calidad y la innovación, trabajamos para construir un mundo más saludable y sostenible para las generaciones presentes y futuras.`,
    };

    const styles = {
        container: {
            display: 'flex',
            alignItems: 'center', // Alinear verticalmente los elementos en el contenedor
            justifyContent: 'center', // Alinear horizontalmente los elementos en el contenedor
            margin: '0 auto',
            maxWidth: '1200px',
            padding: '20px',
            flexDirection: 'row', // Por defecto, en pantallas grandes la imagen está a la izquierda
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column', // En dispositivos móviles, apilar elementos verticalmente
            },
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 50px', // Margen horizontal en pantallas grandes
            position: 'relative',
            overflow: 'hidden',
            paddingTop: '31.25%', // 16:9 Aspect Ratio (9 / 16 * 100), ajustado para ocupar menos espacio
            [theme.breakpoints.down('md')]: {
                display: 'none', // Ocultar la imagen en dispositivos móviles
            },
        },
        textContainer: {
            flex: 2,
            textAlign: 'left',
            maxWidth: '600px',
        },
        image: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'contain', // Ajusta la imagen para que quepa dentro del contenedor sin recortar
            borderRadius: '8px',
            boxShadow: `-1px 1px 12px -3px rgba(0, 0, 0, 0.40)`,
        },
        subtitle: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "18px",
            color: '#9E9E9E',
            fontWeight: 500,
            textAlign: "left",
            marginBottom: '10px',
        },
        title: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "44px",
            color: colors.black.light,
            fontWeight: 500,
            textAlign: "left",
            marginBottom: '20px',
            [theme.breakpoints.down("md")]: {
                fontSize: "24px",
            },
        },
        content: {
            fontFamily: "'Montserrat', sans-serif",
            color: 'rgba(0, 0, 0, 0.7)',
            lineHeight: '1.6',
        },
        secondContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            maxWidth: '1200px',
            padding: '20px',
            boxSizing: 'border-box',
            backgroundColor: '#FFFFFF',
            flexDirection: 'row-reverse', // Por defecto, en pantallas grandes la imagen está a la izquierda
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column-reverse', // En dispositivos móviles, apilar elementos verticalmente y revertir el orden
            },
        },
        secondImageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 50px', // Margen horizontal en pantallas grandes
            position: 'relative',
            overflow: 'hidden',
            paddingTop: '31.25%', // 16:9 Aspect Ratio (9 / 16 * 100), ajustado para ocupar menos espacio
            [theme.breakpoints.down('md')]: {
                display: 'none', // Ocultar la imagen en dispositivos móviles
            },
        },
        cardContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '50px',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                padding: '20px',
            },
        },
        card: {
            flex: 1,
            margin: '0 20px',
            background: "#e6f6fa",
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                marginBottom: '20px',
                maxWidth: '100%',
            },
        },
        tableContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '50px',
            background: "linear-gradient(to top, #e6f6fa, transparent)",
            overflowX: 'auto',
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
        },
        th: {
            padding: '10px',
            borderBottom: '1px solid #ccc',
            textAlign: 'center',
        },
        td: {
            padding: '10px',
            borderBottom: '1px solid #ccc',
            textAlign: 'center',
        },
        valoresTitle: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "44px",
            color: colors.black.light,
            fontWeight: 500,
            textAlign: "center",
            marginBottom: '20px',
            [theme.breakpoints.down("md")]: {
                fontSize: "24px",
            },
        },

    };

    return (
        <React.Fragment>
            <Box mt={5}>
                <Typography variant="h2" gutterBottom style={styles.valoresTitle}>
                    {valoresData.title}
                </Typography>
            </Box>
            <Box sx={styles.cardContainer}>
                {Object.values(expandedData).map((value) => (
                    <Card key={value.id} sx={styles.card}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                {value.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {value.description}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>

            <Box sx={styles.container}>
                <Box sx={styles.imageContainer}>
                    <img src={aboutUsImage} alt="Agua pura america" style={styles.image} />
                </Box>
                <Box sx={styles.textContainer}>
                    <Typography variant="subtitle1" style={styles.subtitle}>
                        {aboutUsData.subtitle}
                    </Typography>
                    <Typography variant="h2" gutterBottom style={styles.title}>
                        {aboutUsData.title}
                    </Typography>
                    <Typography variant="body1" style={styles.content}>
                        {aboutUsData.content}
                    </Typography>
                </Box>
            </Box>

            <Box sx={styles.secondContainer}>
                <Box sx={styles.secondImageContainer}>
                    <img src={aboutUsImage2} alt="Agua pura america" style={styles.image} />
                </Box>
                <Box sx={styles.textContainer}>
                    <Typography variant="subtitle1" style={styles.subtitle}>
                        {visionData.subtitle}
                    </Typography>
                    <Typography variant="h2" gutterBottom style={styles.title}>
                        {visionData.title}
                    </Typography>
                    <Typography variant="body1" style={styles.content}>
                        {visionData.content}
                    </Typography>
                </Box>
            </Box>
            <Box sx={styles.tableContainer}>
                <table style={styles.table}>
                    <thead>
                    <tr>
                        <th style={styles.th}>Días</th>
                        <th style={styles.th}>Horarios</th>
                    </tr>
                    </thead>
                    <tbody>
                    {horariosData.days.map((day) => (
                        <tr key={day.id}>
                            <td style={styles.td}>{day.day}</td>
                            <td style={styles.td}>{day.hours}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Box>

        </React.Fragment>
    );
}

export default AboutUs;
