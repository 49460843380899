import React from "react"
import PageLayout from "../../../components/PageLayout"
import Navbar from "../../../components/NavBar"
import Footer from "../../../components/Footer"
import ProductsScreen from "../../../components/producto";


const ProductPage = () => {


    return (
        <PageLayout background={"white"}>
            <Navbar />
            <ProductsScreen />
            <Footer />
        </PageLayout>
    )
}

export default ProductPage