import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import Home from "./pages/landing/home";
import SegmentosPage from "./pages/landing/segmentos"
import AboutUs from "./pages/landing/aboutUs";
import ProductPage from "./pages/landing/product";
import ScrollToTop from "./components/ScrollToTop"

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/segmentos" element={<SegmentosPage />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/product" element={<ProductPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
