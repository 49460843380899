import React from "react"
import PageLayout from "../../../components/PageLayout"
import Navbar from "../../../components/NavBar"
import Footer from "../../../components/Footer"
import Hero from "../../../components/Hero"
import AboutUs from "../../../components/AboutUs"
import Segmentos from "../../../components/Segmentos"
import Benefits from "../../../components/Benefits"
import Clientes from "../../../components/Clientes"


const HomePage = () => {


  return (
    <PageLayout background={"white"}>
      <Navbar />
      <Hero />
      <AboutUs />
      <Segmentos />
      <Benefits />
      <Clientes />
      <Footer />
    </PageLayout>
  )
}

export default HomePage