import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

function PageLayout({ background, children }) {
  const { pathname } = useLocation()

  useEffect(() => {
    // Modify the document title or perform other actions
    document.title = "Agua Pura"
  }, [pathname])

  return (
    <Box
      width="100vw"
      height="100%"
      minHeight="100vh"
      // bgColor={background}
      // sx={{ overflowX: "hidden" }}
    >
      {children}
    </Box>
  )
}

PageLayout.defaultProps = {
  background: "default",
}

PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
}

export default PageLayout
