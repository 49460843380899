import * as React from "react"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import { Facebook, Instagram } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import colors from "../../assets/theme/colors"

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={3} align="center">
            <Typography variant="h6" color="text.primary" gutterBottom>
              Sobre Nosotros
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Agua Pura América, especialistas en purificación de agua.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} align="center">
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contactanos
            </Typography>
            <Typography variant="body2" color="text.secondary">
              RUTA N°2 MCAL. ESTIGARRIBIA KM.50,600
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: info@aguapura-america.com
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Celular: (0974) 938 214
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Teléfono: 0511/243-989
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} align="center">
            <Typography marginBottom={2} variant="h6" color="text.primary" gutterBottom>
              Seguinos
            </Typography>
            <Link href="https://www.facebook.com/bguillen18/?locale=es_LA" color="inherit" target="_blank">
              <Facebook sx={{ fontSize: 40 }} />
            </Link>
            <Link
              href="https://www.instagram.com/aguapura_americaa"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
              target="_blank"
            >
              <Instagram sx={{ fontSize: 40 }} />
            </Link>
            {/*<Link href="https://www.twitter.com/" color="inherit">*/}
            {/*    <Twitter sx={{ fontSize: 40 }}/>*/}
            {/*</Link>*/}
          </Grid>
          <Grid item xs={12} sm={3} align="center">
            <Typography variant="h6" color="text.primary" gutterBottom>
              ¿En qué te podemos ayudar?
            </Typography>
            <Button
              variant="body2"
              sx={{
                backgroundColor: colors.gradients.dark.main,
                color: colors.gradients.light.main,
                borderRadius: 10,
                marginTop: 2,
                "&:hover": {
                  color: "black",
                },
              }}
              onClick={() => window.open("https://wa.me/+595974938214?text=Hola,%20me%20gustaría%20conocer%20más%20sobre%20los%20servicios.", "_blank")}
            >
              ¡Contáctanos!
            </Button>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            All rights reserved Agua Pura América {new Date().getFullYear()}. |{" "}
            <Link color="inherit" href="https://brunolird.com/">
              Powered by B.L
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}