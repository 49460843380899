import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import "aos/dist/aos.css";
import dummyDataClientes from "./dummyDataClientes";
import Carousel from "react-elastic-carousel";

function Clientes() {
  const theme = useTheme();

  const styles = {
    mainContainer: {
      background: "linear-gradient(to bottom, #e6f6fa, transparent)", // Degradado desde #e6f6fa hasta transparente
      height: "100%",
      // display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      paddingY: 6,
      paddingX: 10,
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 6,
        paddingX: 1,
      },
    },
    image: {
      width: "100%",
      maxWidth: "497px",
      height: "auto",
    },
    companyLogo: {
      width: "110px",
      height: "110px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%", // Asegura que el contenedor ocupe toda la altura disponible
    },
  };

  const breakPoints = [
    { width: 500, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 6 },
    { width: 1500, itemsToShow: 8 },
  ];

  return (
    <Box sx={styles.mainContainer}>
      <Typography textAlign={"center"} variant="h2" mt={2} mb={5}>
        Clientes
      </Typography>
      <Carousel
        breakPoints={breakPoints}
        pagination={false}
        showArrows={false}
        enableAutoPlay={true}
        autoPlaySpeed={5000}
      >
        {dummyDataClientes.map((item, index) => (
          <Box key={index} sx={styles.logoContainer}>
            <img src={item.logo} alt={item.alt} style={styles.companyLogo} />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
}

export default Clientes;
