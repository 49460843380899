import React, { useState } from "react";
import { Box, Typography, Card, CardContent, CardMedia, Modal, IconButton, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import colors from "../../assets/theme/colors";
import productImage1 from "../../assets/images/productos/pro1.webp";
import productImage2 from "../../assets/images/productos/pro2.webp";
import productImage8 from "../../assets/images/productos/pro8.webp";
import productImage14 from "../../assets/images/productos/pro14.webp";
import productImage15 from "../../assets/images/productos/pro15.webp";
import productImage21 from "../../assets/images/productos/pro21.webp";
import productImage33 from "../../assets/images/productos/ap global system.webp";
import productImage34 from "../../assets/images/productos/ape global manual y automatico.webp";
import productImage35 from "../../assets/images/productos/bebedero con filtro.webp";
import productImage36 from "../../assets/images/productos/bebedero de mesada 2.webp";
import productImage37 from "../../assets/images/productos/cana distribuidora.webp";
import productImage38 from "../../assets/images/productos/carbon recargable.webp";
import productImage39 from "../../assets/images/productos/carbon2.webp";
import productImage40 from "../../assets/images/productos/cartucho bayoneta filtracion.webp";
import productImage41 from "../../assets/images/productos/cartucho bayoneta gac.webp";
import productImage42 from "../../assets/images/productos/cartucho de carbon 20.webp";
import productImage43 from "../../assets/images/productos/cartucho de carbon granulado y cartucho sedimento.webp";
import productImage44 from "../../assets/images/productos/cartucho remineralizador incrementador de ph.webp";
import productImage45 from "../../assets/images/productos/catucho de carbon compacto.webp";
import productImage46 from "../../assets/images/productos/docificador.webp";
import productImage47 from "../../assets/images/productos/ducha libre.webp";
import productImage48 from "../../assets/images/productos/filtro azul big 10.webp";
import productImage49 from "../../assets/images/productos/filtro lavadora 2.webp";
import productImage50 from "../../assets/images/productos/filtro para termo calefon.webp";
import productImage51 from "../../assets/images/productos/grifo.webp";
import productImage52 from "../../assets/images/productos/lampara uva.webp";
import productImage53 from "../../assets/images/productos/malla de sedimentos.webp";
import productImage54 from "../../assets/images/productos/osmosis con bomba.webp";
import productImage55 from "../../assets/images/productos/osmosis inversa.webp";
import productImage56 from "../../assets/images/productos/presurizador.webp";
import productImage57 from "../../assets/images/productos/valvula automatica.webp";



function ProductSection() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const products = [
        { id: 1, title: "AP 1 natura light", description: "El sistema utiliza una carga especial capaz de combatir todo tipo de contaminantes, incluido el cloro y sus compuestos, el hierro disuelto, los virus, las sales de metales pesados, etc. Además, suaviza significativamente el agua, haciéndola segura para los electrodomésticos.", image: productImage1 },
        { id: 2, title: "AP 2 basic", description: "El sistema utiliza una carga especial capaz de combatir todo tipo de contaminantes, incluido el cloro y sus compuestos, el hierro disuelto, los virus, las sales de metales pesados, etc. Además, suaviza significativamente el agua, haciéndola segura para los electrodomésticos.", image: productImage2 },
        { id: 8, title: "Carbon filter cto", description: "Los filtros de carbón activado se utilizan para la filtración de agua con una eficiencia de retención de cloro de hasta el 75 % con un aporte de cloro de 2 ppm. También eliminan algo de colorante.", image: productImage8 },
        { id: 14, title: "Pulsador de agua caliente", description: "Grifo pulsador para agua caliente con seguro de presión.", image: productImage14 },
        { id: 15, title: "Toma de agua", description: "Mediante este sistema se eliminan los problemas de atascos de las arquetas de bombeo de agua destinada a estaciones potabilizadoras.", image: productImage15 },
        { id: 21, title: "Membrana", description: "Bloquea los contaminantes y las partículas no deseadas presentes en el agua.", image: productImage21 },
        {id : 33, title: "Ap global system", description: "Se utiliza para eliminar el hierro, el manganeso y el sulfuro de hidrógeno disueltos y no disueltos. El sistema funciona mediante la oxidación catalítica de iones de hierro y manganeso.", image: productImage33}
        ,{id : 34, title: "Ap global manual y automatico", description: "El sistema de filtro de agua Ap Global es un sistema de filtro de agua de alta calidad que elimina el hierro, el manganeso y el sulfuro de hidrógeno disueltos y no disueltos.", image: productImage34}
        ,{id : 35, title: "Bebedero con filtro", description: "El bebedero con filtro es un sistema de purificación de agua que elimina el cloro, el plomo, el mercurio, el arsénico, el cromo y otros contaminantes del agua.", image: productImage35}
        ,{id : 36, title: "Bebedero de mesada", description: "El bebedero de mesada es un sistema simple y práctico con opciones de temperatura para frio y caliente.", image: productImage36}
        ,{id : 37, title: "Caña distribuidora", description: "Se instala en la carcasa del filtro de agua para garantizar el movimiento del agua durante la limpieza y el retrolavado del sistema de tratamiento de agua.", image: productImage37}
        ,{id : 38, title: "Carbon recargable", description: "Estos filtros se utilizan para eliminar la materia orgánica, algunos microorganismos, el color, el olor, el sabor y el cloro del agua y de las soluciones que contienen contaminación orgánica..", image: productImage38}
        ,{id : 39, title: "Carbon", description: "Filtro de carbón, cartucho de purificación de agua, consiste en carbón activado que adsorbe sustancias orgánicas, compuestos de cloro, pesticidas y herbicidas en su superficie.", image: productImage39}
        ,{id : 40, title: "Cartucho bayoneta filtracion", description: "El cartucho se caracteriza por una alta eficiencia en la eliminación de olores desagradables y la neutralización del cloro.", image: productImage40}
        ,{id : 41, title: "Cartucho bayoneta gac", description: "El cartucho se caracteriza por una alta eficiencia en la eliminación de olores desagradables y la neutralización del cloro.", image: productImage41}
        ,{id : 42, title: "Cartucho de carbon 20'", description: "Filtro de carbón, cartucho de purificación de agua, consiste en carbón activado que adsorbe sustancias orgánicas, compuestos de cloro, pesticidas y herbicidas en su superficie, de 20 pulgadas.", image: productImage42}
        ,{id : 43, title: "Cartucho de carbon granulado y cartucho sedimento", description: "Filtro de carbón, cartucho de purificación de agua, consiste en carbón activado que adsorbe sustancias orgánicas, compuestos de cloro, pesticidas y herbicidas en su superficie.", image: productImage43}
        ,{id : 44, title: "Cartucho remineralizador incrementador de ph", description: "El cartucho remineralizador incrementador de ph es un cartucho de purificación de agua que aumenta el pH del agua y agrega minerales esenciales al agua.", image: productImage44}
        ,{id : 45, title: "Catucho de carbon compacto", description: "Cartucho de purificación de agua, adsorbe sustancias orgánicas, compuestos de cloro, pesticidas y herbicidas en su superficie.", image: productImage45}
        ,{id : 46, title: "Docificador", description: "El docificador es un sistema bombas dosificadoras proporcionales digitales de solenoide con control por microprocesador. Las bombas están fabricadas con un diseño antiácido, el panel de control está protegido de la radiación UV mediante una película.", image: productImage46}
        ,{id : 47, title: "Ducha libre", description: "Los filtros de ducha ofrecen beneficios para la salud al filtrar el cloro y otros contaminantes dañinos del agua.", image: productImage47}
        ,{id : 48, title: "Filtro azul big 10'", description: "El filtro principal está montado directamente en la tubería de agua, lo que proporciona una filtración de agua de alta velocidad. La carcasa de dicho filtro tiene un diseño especial: está montada de forma segura en la línea principal y, si es necesario, se puede desmontar fácilmente para cambiar el cartucho.", image: productImage48}
        ,{id : 49, title: "Filtro lavadora", description: "El sistema antical se basa en el efecto de un campo magnético constante sobre el agua que lo atraviesa. Esta acción evita la formación de cal en circuitos hidráulicos y sistemas de calefacción.", image: productImage49}
        ,{id : 50, title: "Filtro para termo calefon", description: "Filtro ideal para prevenir y eliminar el riesgo de incrustaciones de cal y corrosión, asegurando una mayor vida útil de los electrodomésticos y reduciendo el consumo de energía y agua.", image: productImage50}
        ,{id : 51, title: "Grifo", description: "El grifo es una válvula mecánica que sirve para regular, liberar o bloquear el flujo del agua desde una tubería.", image: productImage51}
        ,{id : 52, title: "Lampara UVa", description: "El purificador UV es sin duda su mejor opción para una desinfección completa en cualquier lugar que requiera agua purificada, libre de bacterias y para evitar cualquier otra contaminación del agua antes de que se se beba o utilice en algún proceso. Es útil en casi cualquier aplicación en la que se requiera agua pura, segura y libre de microbios.", image: productImage52}
        ,{id : 53, title: "Malla de sedimentos", description: "El filtro de sedimentos se puede utilizar en la entrada de agua de la casa ( después del medidor de agua). Principalmente para evitar que el agua tenga impurezas como; arena, basura y suciedad.", image: productImage53}
        ,{id : 54, title: "Osmosis con bomba", description: "Al disponer de bomba permite trabajar con baja presión de entrada. Conexiones rápidas, que aportan una mayor seguridad y fiabilidad. Contiene membrana Optima Eco 1:1 con un bajo nivel de rechazo, lo que otorga a este equipo un mayor rendimiento con un menor consumo de agua.", image: productImage54}
        ,{id : 55, title: "Osmosis inversa", description: "El sistema de ósmosis inversa es un sistema de purificación de agua que elimina el 99% de los contaminantes del agua, incluidos el cloro, los metales pesados, los pesticidas, los herbicidas, los virus y las bacterias.", image: productImage55}
        ,{id : 56, title: "Presurizador", description: "El presurizador es un sistema de bombeo de agua que aumenta la presión del agua en la tubería de agua.", image: productImage56}
        ,{id : 57, title: "Valvula automatica", description: "La válvula automática es un sistema de control de válvulas que se utiliza para controlar el flujo de agua en una tubería de agua.", image: productImage57}


    ];

    const handleOpen = (product) => {
        setSelectedProduct(product);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedProduct(null);
    };

    const styles = {
        sectionTitle: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "44px",
            color: colors.black.light,
            fontWeight: 500,
            textAlign: "center",
            marginBottom: '20px',
            [theme.breakpoints.down("md")]: {
                fontSize: "24px",
            },
        },
        cardContainer: {
            padding: '50px',
            [theme.breakpoints.down('md')]: {
                padding: '20px',
            },
        },
        card: {
            background: "#e6f6fa",
            borderRadius: 3,
            height: '450px',
        },
        cardMedia: {
            height: 300,
            width: 250,
        },
        cardContent: {
            textAlign: 'center',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContent: {
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(4),
            boxShadow: theme.shadows[5],
            outline: 'none',
            maxWidth: isSmallScreen ? '90%' : '50%',
            minWidth: '300px',
            margin: '0 auto',
            overflowY: 'auto', // Agregar scroll vertical si el contenido es demasiado grande
            maxHeight: '80vh', // Establecer una altura máxima para el scroll
        },
        modalImage: {
            maxWidth: 400, // Ajuste del ancho máximo de la imagen según el tamaño de la pantalla
            marginBottom: theme.spacing(2),
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
        },
        modalDescription: {
            fontFamily: "'Montserrat', sans-serif",
        },
        closeButton: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            zIndex: 1,
            [theme.breakpoints.down('sm')]: {
                top: theme.spacing(0.5),
                right: theme.spacing(0.5),
            },
        },
    };

    return (
      <>
          <Box>
              <Typography variant="h6" gutterBottom style={{...styles.sectionTitle, color: "white"}}>
                  Productos
              </Typography>
          </Box>
          <Box>
              <Typography variant="h6" gutterBottom style={styles.sectionTitle}>
                  Productos
              </Typography>
          </Box>
          <Box sx={styles.cardContainer}>
              <Grid container spacing={8} justifyContent="center" alignItems="center">
                  {products.map((product) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={product.id} onClick={() => handleOpen(product)}>
                        <Card sx={styles.card}>
                            <CardMedia component="img" height="330" image={product.image} alt={product.title} />
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    {product.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={{ display: '-webkit-box',
                                    WebkitLineClamp: 3,  // Limitar a 3 líneas
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',}}>
                                    {product.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                  ))}
              </Grid>

          </Box>
          {selectedProduct && (
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="product-modal-title"
              aria-describedby="product-modal-description"
              sx={styles.modal}
            >
                <Box sx={styles.modalContent}>
                    <IconButton sx={styles.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <CardMedia
                      component="img"
                      sx={styles.modalImage}
                      image={selectedProduct.image}
                      title={selectedProduct.title}
                    />
                    <Typography variant="h5" id="product-modal-title">
                        {selectedProduct.title}
                    </Typography>
                    <Typography variant="body1" id="product-modal-description" sx={styles.modalDescription}>
                        {selectedProduct.description}
                    </Typography>
                </Box>
            </Modal>
          )}
      </>
    );
}

export default ProductSection;
