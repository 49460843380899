import React from "react"
import PageLayout from "../../../components/PageLayout"
import Navbar from "../../../components/NavBar"
import Footer from "../../../components/Footer"
import Segmentos from "../../../components/Segmentos"
import Clientes from "../../../components/Clientes"
import Hero3 from "../../../components/Hero3"
import Descripcion from "../../../components/Segmentos/descripcion"


const SegmentosPage = () => {


  return (
    <PageLayout background={"white"}>
      <Navbar />
      <Hero3 />
      <Segmentos />
      <Descripcion />
      <Clientes />
      <Footer />
    </PageLayout>
  )
}

export default SegmentosPage